import React, { Component } from 'react';

async function getPicUrls(access_token, ig_userid, ig_limit ) {
    const URL = "https://graph.instagram.com/"+ig_userid+"/media?access_token="+access_token;

    const response = await fetch(URL);
    const mediaData = await response.json();
    const mediaList = await mediaData.data;
    if (response.ok == false){
        return [""];
    }
    let pictureUrls = [];
    let mediaDataPic ;
    let post_counter = 0;
    for (const [key, value] of Object.entries(mediaList)) {
        let responsePic = await fetch('https://graph.instagram.com/'+value.id+'?fields=media_type,media_url&access_token='+access_token);
        mediaDataPic = await responsePic.json();
        if (mediaDataPic.media_type == "IMAGE") {
            pictureUrls.push(mediaDataPic)
            post_counter++;
        }
        if (post_counter>=ig_limit){
            break;
        }

    }
    return pictureUrls;
}

//getPicUrls()

class RecentProjects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pic_list : []
        };
      }



    async componentDidMount() {
        const access_token = this.props.IGTOKEN;
        const ig_userid = this.props.IGID;
        const ig_limit = 3;
        //const pic_lists = await getPicUrls(access_token, ig_userid, ig_limit );
        //this.setState({ pic_list : pic_lists });

    }

    render(){

        let picList = this.state.pic_list && this.state.pic_list.length > 0 ?
        this.state.pic_list.map( (p, index) =>
            <div className="col-lg-3 col-md-3 pb-3 px-0" key={p.id} >
                <div className="d-flex justify-content-center">
                <div className="image" style={{maxWidth: "80%"}}>
                    <img src={p.media_url} alt={p.id}/>
                </div>
                </div>
            </div>

        ) : <span></span>;

        return (
            <section className="projects-area bg-color pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <div className="row justify-content-center">
                            <div className="col-12 pt-3">
                                <span className="sub-title cella-font" style={{  fontSize:"28px",color: '#FF13A7'}}>
                                    INSTAGRAM FEED
                                </span>
                            </div>
                        </div>

                        <div className="cella-font">Follow us to hear more about this project during the countdown</div>
                        <div className="cella-font">We post updates and new images every day</div>
                        <p>    <a href="https://www.instagram.com/nifty_riots/" className="d-block pt-5" target="_blank">
                                <i className='bx bxl-instagram bx-instagram-custom'></i>
                            </a>
                            </p>
                    </div>

                    <div className="row justify-content-center">
                        { this.props.useIG?
                            {picList}
                        :
                        <React.Fragment>
                            <div className="col-lg-3 col-md-3 pb-3 px-0"  >
                                <div className="d-flex justify-content-center">
                                <div className="image" style={{maxWidth: "80%"}}>
                                <img src={this.props.S3_ADDRESS + "webimages/" + "newkokeshi135_QQQQ_banditsni_264801_own.jpg_QQQQ_groupa_14.jpg_QQQQ_.png"} />
                                    
                                    
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 pb-3 px-0"  >
                                <div className="d-flex justify-content-center">
                                <div className="image" style={{maxWidth: "80%"}}>
                                <img src={this.props.S3_ADDRESS + "webimages/" + "newkokeshi146_QQQQ_mig6_own.jpg_QQQQ_groupa_14.jpg_QQQQ_.png"} />
                                </div>
        
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 pb-3 px-0" >
                                <div className="d-flex justify-content-center">
                                <div className="image" style={{maxWidth: "80%"}}>
                                <img src={this.props.S3_ADDRESS + "webimages/" + "newkokeshi177_QQQQ_banditsni_264801_own.jpg_QQQQ_groupa_14.jpg_QQQQ_.png"} />
                                </div>
                                </div>
                            </div>
                        </React.Fragment>

                        }

                    </div>
                </div>
            </section>
        );
    }

}

export default RecentProjects;
